import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/base/layout';
import Seo from '../components/base/seo';
import logFirebaseEvent from '../utils/firebase';
import CustomLink from '../components/base/Link';

const routes = require('../routes');

const ClassDetails = ({ pageContext: { class_obj } }) => {
  React.useEffect(() => {
    logFirebaseEvent('class_subject_open', {
      class_name: class_obj.class_name,
      subject: class_obj.subject,
    });
  }, []);
  return (
    <Layout>
      <Seo
        title={`${class_obj.class_name} | ${class_obj.subject}`}
        keywords={[
          class_obj.class_name,
          class_obj.subject,
          ...JSON.parse(process.env.GATSBY_BASE_KEYWORDS),
        ]}
      />

      <main className="my-2 mx-4">
        <div>
          <Link to="/">
            <StaticImage
              src="../images/v-left.png"
              alt="hw-logo"
              placeholder="blurred"
              layout="fixed"
              width={10}
              height={18}
              className="my-4"
            />
          </Link>
          <div className="font-bold text-lg">
            {class_obj.class_name}
            {' '}
            {class_obj.subject}
          </div>
          <div className="font-bold text-2xl">
            Download sample paper PDFs for
            {' '}
            {class_obj.class_name}
            {' '}
            {class_obj.subject}
          </div>
          <div>
            <div className="grid grid-cols-2 gap-4 my-4">
              {class_obj.books.map((book) => (
                <div className="h-full items-stretch">
                  <CustomLink
                    key={book.book_id}
                    to={`/${routes.book}/${class_obj.class_name}/${class_obj.subject}/${book.name}-${book.book_id}`}
                    firebaseEvent={{
                      eventName: 'book_click',
                      eventParams: { id: book.book_id, value: book.name },
                    }}
                  >
                    <div className="bg-white rounded-lg shadow-md overflow-hidden">
                      <div className="">
                        <img
                          src={book.photo_url}
                          alt="book1"
                          className="w-full h-60 object-cover rounded"
                        />
                      </div>
                      <div className="m-4">
                        <span className="font-semibold text-sm">
                          {book.name}
                        </span>
                        <span className="block font-light text-sm">
                          {book.content_count}
                          {' '}
                          papers
                        </span>
                      </div>
                    </div>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ClassDetails;
